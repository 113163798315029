<template>
  <v-form ref="form" class="mx-2" method="post" enctype="multipart/form-data">
    <v-container id="ENTI" fluid tag="section" >
      <v-row>
        <v-col :cols="isEditEnti ? '4' : '12'">
          <base-material-card
            color="white"
            iconcolor="darkred"
            icon="mdi-book-multiple"
            :title="$t('enti.titolo')"
            class="px-5 py-3"
          >
            <v-container v-if="isCreatedCorrectly" v-resize="onContainerChange">
              <v-row>
                <v-col>
                  <v-card-title>
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      :label="$t('enti.ricerca')"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-card-title>
                </v-col>
                <v-col align="end">
                  <v-btn color="darkred" width="180px" @click="onExportGrid" v-show="false">
                    <v-icon left> mdi-export </v-icon>
                    {{ $t("esporta") }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
            <v-data-table
              style="max-height:600px; overflow:auto"
              id="tblEnti"
              :items="enti.source"
              :headers="headersColumns"
              :search="search"
              :value="[enti.selected]"
              @click:row="onTableRowClick"
              single-select
              :items-per-page="-1"
              class="elevation-1"
              :loading="isloading_enti"
              loading-text="Caricamento dati in corso..."
            >
            </v-data-table>
          </base-material-card>
        </v-col>

        <v-col v-if="isEditEnti" cols="8">
          <base-material-card
            color="white"
            iconcolor="darkred"
            icon="mdi-book"
            :title=" $t('enti.ente') + ': ' + enti.selected.cDescrizione"
            class="px-5 py-3"
          >
          <v-card flat>
              <v-row>
                <v-card-title
                  class="black--text"
                  style="margin-bottom: 20px; margin-top: 20px"
                  >{{ $t("anagrafica") }}
                </v-card-title>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="enti.selected.cSigla"
                    :label="$t('enti.siglaObbl')"
                    disabled
                  >
                  </v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="enti.selected.cDescrizione"
                    :label="$t('enti.descrizioneObbl')"
                    :rules="[rules.required]"
                    maxlength="50"
                    :disabled="shouldDisable"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="enti.selected.cpIva"
                    :label="$t('enti.piva')"
                    :rules="[rules.required]"
                    maxlength="11"
                    :disabled="shouldDisable"
                  >
                  </v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="enti.selected.cIpa"
                    :label="$t('enti.ipa')"
                    :rules="[rules.required]"
                    maxlength="10"
                    :disabled="shouldDisable"
                  >
                  </v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="enti.selected.cPec"
                    :label="$t('enti.pec')"
                    :rules="[rules.required]"
                    maxlength="40"
                    :disabled="shouldDisable"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row> 
                <v-col>
                  <v-text-field
                    v-model="enti.selected.cIndirizzo"
                    :label="$t('enti.indirizzo')"
                    :rules="[rules.required]"
                    maxlength="100"
                    :disabled="shouldDisable"
                  >
                  </v-text-field>
                </v-col>
              </v-row>

              <v-divider class="mx-4"></v-divider>
              <v-row
                style="margin-top: 20px; margin-left: 20px; margin-bottom: 10px"
              >
                <v-alert
                  id="alertDettaglio"
                  shaped
                  :value="isAlertDetailVisible"
                  :type="alertType"
                  dismissible
                >
                  {{ messaggioAlert }}
                </v-alert>
              </v-row>

              <v-row
                style="margin-top: 20px; margin-left: 20px; margin-bottom: 10px"
              >
                <v-btn
                  tile
                  color="darkred"
                  v-if="!isHidden"
                  v-on:click="isHidden = true"
                  @click="onEditClick"
                >
                  <v-icon left> mdi-pencil </v-icon>
                  {{ $t("modifica") }}
                </v-btn>
                <v-btn
                  tile
                  color="darkred"
                  v-if="isHidden"
                  @click="onSaveClick"
                >
                  <v-icon left> mdi-content-save </v-icon>
                  {{ $t("salva") }}
                </v-btn>
                <v-btn
                  id="btnChiudi"
                  tile
                  color="darkred"
                  @click="onCloseDetail"
                >
                  <v-icon left> mdi-door-open </v-icon>
                  {{ $t("chiudi") }}
                </v-btn>
              </v-row>
            </v-card>
          </base-material-card>
        </v-col>
      </v-row>
      <v-row>
        <v-alert
          id="alertGrid"
          shaped
          :value="isAlertGridVisible"
          :type="alertType"
          dismissible
        >
          {{ messaggioAlert }}
        </v-alert>
      </v-row>        
    </v-container>
  </v-form>
</template>

<script>
import Vue from "vue";
import Component from "vue-class-component";

import i18n from "@/i18n";
import Utils from "@/utils/Utils";

import TipologicheService from "@/service/TipologicheService";
import EntiService from "@/service/EntiService";

@Component
export default class Enti extends Vue {
  enti = {
    selected: null,
    source: [],
  };

  headersColumns = [
    {
      text: "Sigla",
      align: "start",
      value: "cSigla",
    },
    { text: "Descrizione", value: "cDescrizione" },
    { text: "P. IVA", value: "cpIva" },
    { text: "Ipa", value: "cIpa" },
    { text: "Pec", value: "cPec" },
    { text: "Indirizzo", value: "cIndirizzo" },
  ];

  // Variabili
  isEditEnti = false;
  isAlertGridVisible = false;
  isAlertDetailVisible = false;
  isCreatedCorrectly = false;
  isloading_enti = false;
  shouldDisable = true;
  enteShouldDisable = true;
  isHidden = false;
  saveClicked = false;
  dialogDisattivaEnte = false;

  dimension = 0;

  messaggioAlert = "";
  alertType = "error";
  search = "";

  rules = Utils.rules;

created() {
    let verLoadingPage = Utils.verifyLoadingPage(this.$store, "W_PROFILAZIONEUTENTE");
    switch (verLoadingPage) {
      case 0: // All OK
        this.isCreatedCorrectly = true;
        var enteId = this.$store.state.enteSel.id;
        this.loadEnti();
        break;

      case 1: // Utente non loggato
        AuthService.logout();
        break;

      case 2: // Ente non selezionato
        this.$router.push({ name: "Dashboard" });
        break;

      case 3: // Permesso alla pagina non presente
        this.$router.push({ name: "Dashboard" });
    }
  }

  onTableRowClick(enteSel) {
    // Setto la riga selezionata
    this.enti.selected = enteSel;

    console.log("Ente selezionato: ");
    console.log(this.enti.selected);

    //this.manageEntiVisibility(false);
    // TODO
    this.isHidden = false;
    this.shouldDisable = true;
    this.isEditEnti = true;
  }

  onExportGrid() {
    // TODO
  }

  onContainerChange() {
    let self = this;
    // console.log("onContainerChange");
    self.$nextTick(function () {
      self.dimension = document.getElementById("tblEnti").offsetWidth;
      return self.dimension;
    });
  }

  onEditClick() {
    this.shouldDisable = false;
  }

  onSaveClick() {
    this.saveClicked = true;
    var formIsValid = this.$refs.form.validate();
    if (!formIsValid) {
      this.alertType = "error";
      // In caso di errore del salvataggio continuare con l'editing
      this.messaggioAlert = i18n.t("erroreCampiObbligatori");
      this.showHide_alertDetail(8000);
    } else {
      // TODO

      if (this.enti.selected.id > 0) {
        // Salvo il dato sul DB
        this.saveEnteDto(this.enti.selected.id);
      } else {
        // Creo il nuovo record
        // Da non gestire nella prima versione
        // this.createEnteDto();
      }
    }
  }

  onCloseDetail() {
    this.isEditEnti = false;
    this.onContainerChange();
    this.shouldDisable = true;
    this.isHidden = false;

  }

  showHide_alertGrid(millisecondInterval, event) {
    //console.log('Hide alert');
    this.isAlertGridVisible = true;
    // `event` is the native DOM event
    window.setTimeout(() => {
      this.isAlertGridVisible = false;
      console.log(
        "hide alert after " + millisecondInterval / 1000 + " seconds"
      );
    }, millisecondInterval);
  }

  showHide_alertDetail(millisecondInterval, event) {
    //console.log('Hide alert');
    this.isAlertDetailVisible = true;
    // `event` is the native DOM event
    window.setTimeout(() => {
      this.isAlertDetailVisible = false;
      //console.log("hide alert after " + millisecondInterval/1000 + " seconds");
    }, millisecondInterval);
  }

  /// *************************
  /// DATABASE
  /// *************************

  loadEnti(isEditing) {
    var self = this;
    if (isEditing) self.enteShouldDisable = true;
    self.isloading_enti = true;
    EntiService.getEnti(
      (resp) => {
        self.isloading_enti = false;
        console.log("GetEnti");
        console.log(resp);

        self.enti.source = resp;
      },
      (err) => {
        console.log("Errore GetEnti");
        console.log(err);
        self.alertType = "error";
        self.messaggioAlert = i18n.t("erroreCaricamentoEnti");
        self.showHide_alertGrid(8000);
        self.isloading_enti = false;
      }
    );
  }

  saveEnteDto(id) {
    var self = this;

    EntiService.updateEnte(
      self.enti.selected,
      (resp) => {
        console.log("UpdateEnte");
        console.log(resp);
        if (resp.result == 1) {

          // Buon fine
          self.alertType = "success";
          self.messaggioAlert = resp.userMsg.message;
          self.showHide_alertDetail(5000);
          // Aggiorno la griglia principale
          self.updateGridEnte(id);
        } else {
          // KO
          self.alertType = "error";
          self.messaggioAlert = resp.userMsg.message;
          self.showHide_alertDetail(8000);
        }

        self.isHidden = !self.isHidden;
        self.shouldDisable = true;
        console.log(resp.userMsg.message);
      },
      (err) => {
        console.log(err);
        self.alertType = "error";
        self.messaggioAlert = Utils.getErrorMessage(err);
        self.showHide_alertDetail(8000);
      }
    );
  }

  updateGridEnte(id, onSuccess) {
    var self = this;

    EntiService.getEnte(
      id,
      (resp) => {
        console.log("Capitolo:");
        console.log(resp);

        var enteTmp = resp;

        if (typeof onSuccess === "function") onSuccess(resp);
        // Aggiorno la griglia
        var found = false;
        for (let i = 0; i < self.enti.source.length; i++) {
          var item = self.enti.source[i];
          if (item.id == id) {
            found = true;
            self.enti.source.splice(i, 1);
            self.enti.source.push(enteTmp);
            self.enti.selected = enteTmp;
            break;
          }
        }
        if (!found) {
          // Caso di nuovo oggetto
          self.enti.source.push(enteTmp);
          self.enti.selected = enteTmp;
        }
      },
      (err) => {
        console.log(err);
        self.alertType = "error";
        self.messaggioAlert = Utils.getErrorMessage(err);
        self.showHide_alertGrid(8000);
      }
    );
  }

}
</script>